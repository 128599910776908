import React, { useState, useEffect } from "react";
import BookingDialogueBox from "./BookingDialogueBox";

const Raqt_item = ({ racquetId, imageUrl, brandName, modelName, addToCart}) => {
  const [isDialogueOpen, setIsDialogueOpen] = useState(false);

  const handleBookNowClick = () => {
    setIsDialogueOpen(true);
  };

  const handleCloseDialogue = () => {
    setIsDialogueOpen(false);
  };

  return (
    <div className="flex flex-col w-40 bg-white rounded-lg overflow-hidden  items-center justify-center lg:w-30 lg:h-30 sm:w-30 sm:h-30 ">

      <img className="w-full h-full object-contain " src={`${imageUrl}`} alt="Racquet" />

      <div className="p-4 text-center">
      <div className="font-cocoextrabold text-xl text-gray-800">{brandName} {modelName}</div>

        <button
          onClick={handleBookNowClick}
          className="text-red-500 font-cocoextrabold  hover:text-red-700 focus:outline-none"
        >
          BOOK NOW
        </button>
        <BookingDialogueBox
          isOpen={isDialogueOpen}
          onClose={handleCloseDialogue}
          racquetName={brandName + " " + modelName}
          racquetId = {racquetId}
          addToCart={addToCart} // Pass addToCart function as a prop
        />
      </div>
    </div>
  );
};

export default Raqt_item;
