import React, { useState, useEffect } from "react";
import BookingDialogueBox from "./BookingDialogueBox";
import Raqt_item from "./Raqt_item";
import ContactUs from "./ContactUs";

const Racquets = ({ addToCart }) => {
  const [isDialogueOpen, setIsDialogueOpen] = useState(false);
  const [racquets, setRacquets] = useState([]);

  const handleBookNowClick = () => {
    setIsDialogueOpen(true);
  };

  const handleCloseDialogue = () => {
    setIsDialogueOpen(false);
  };

  // Function to fetch racquet data from the server
  const fetchRacquets = async () => {
    try {
      const response = await fetch(
        "https://10md8xfvaj.execute-api.us-east-1.amazonaws.com/prod/api/get_inventory"
      ); // Adjust the URL accordingly
      const data = await response.json();
      setRacquets(data);
    } catch (error) {
      console.error("Error fetching racquets:", error);
    }
  };

  // Fetch racquet data when the component mounts
  useEffect(() => {
    fetchRacquets();
  }, []);

  return (
    <section id='racquetsSection' className="scroll-mt-28 pt-28">
      <div className="grid grid-cols-1 justify-center items-center">
        <div className="grid grid-cols-1 gap-6 w-full sm:px-16 px-6">
          <div className="flex items-center w-full">
            <p className="font-cocobold text-black items-center text-[18pt] mt-5 md:text-[40pt] px-4">
              {" "}
              CHOOSE YOUR RACQUET
            </p>
          </div>
          <div className="w-full h-[1px] bg-[#000] my-12"></div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4 mt-24">
            {racquets.map((racquet) => (
              <Raqt_item
                key={racquet[0]}
                racquetId={racquet[0]}
                brandName={racquet[2]}
                modelName={racquet[1]}
                imageUrl={racquet[3]}
                addToCart={addToCart}
              />
            ))}
          </div>
          <div className="w-full h-[1px] bg-[#000] mt-12 sm:mt-2"></div>
        </div>
        <div id="contactUs" className="flex w-full overflow-hidden mt-16 py-6 lg:flex-row gap-4 flex-col sm:py-16 items-center">
          <div className="flex-1 flex justify-center items-start flex-col xl:px-16 sm:px-16 px-6 mt-0 md:mt-0">
            <ContactUs />
          </div>
          <div className='flex flex-1 justify-center items-center  lg:order-last'>
            <img
              src='../Images/inverted-bigsmile.png'
              alt="GoRaqt Logo"
              className='h-[350px] md:h-[400px] lg:h-[500px] xl:h-[700px]'
            />
          </div>
        </div>
        <div className="w-[90%] h-[1px] bg-[#000] mt-12 ml-12 sm:mt-8"></div>
      </div>
    </section>
  );
};

export default Racquets;
