import React from 'react'
import {FaChevronDown} from "react-icons/fa";
import "./Landing.css";
import {Link} from "react-scroll";

export default function Landing() {
	return (
		<section id='landing'
		         className='relative min-h-screen flex bg-black w-full overflow-hidden mt-16 py-6 xl:flex-row gap-4 flex-col sm:py-16 items-center'>
			<div className='flex flex-1 justify-center items-center order-first xl:order-last xl:float-right'>
				<img
					src='../Images/logo_smile.png'
					className='logo-smile'
					alt={"GoRaqt Logo"}
				/>
			</div>
			<div className='flex flex-1 justify-center items-center order-last float-left xl:order-first xl:float-left'>
				<div className='flex-1 flex justify-center items-start flex-col lg:pl-6 md:pl-2 sm:px-16 px-6 md:w-2/3'>
					<div className='shrink-0 flex flex-row justify-between items-center w-full xl:pt-36'>
						<img
							src="../Images/logo_white.png"
							alt="Logo"
							className='logo-white'
						/>
					</div>
					<div className='flex flex-row justify-between items-center w-full'>
						<p className='font-cocobold text-white landing-lg-text mt-5 leading-[1.2]'>
							<span className="no-wrap">Demo, Rent and Buy Racquets — Courtside.</span><br/><span
							className="no-wrap">GoRaqt delivers where you play.</span>
						</p>
					</div>
					<div className="w-full h-[1.53px] bg-[#FFF] mt-2"></div>
					<div className='flex flex-row justify-between items-center w-full'>
						<p className='font-cocomedium text-white landing-md-text mt-1 xl:w-full'>
							Let the pro shop come to you. Play with the latest racquet models, <span
							className="text-[#DFFF4F]">when</span> and <span className="text-[#DFFF4F]">where</span> you
							want.
						</p>
					</div>
					<div className='flex flex-row justify-between items-center w-full pb-20'>
						<p className='font-cocomedium text-[#DFFF4F] landing-md-text mt-7'>
							<Link
								to="racquetsSection"
								smooth={true}
								duration={500}
								className="link inline text-[#DFFF4F] underline cursor-pointer"
								style={{marginTop: 2 + 'px'}}
							>
								Reserve now
							</Link> and get a free can of Dunlop tennis balls. <br/>
							Let us know 							<Link
							to="contactUs"
							smooth={true}
							duration={500}
							className="link inline text-[#DFFF4F] underline cursor-pointer"
							style={{marginTop: 2 + 'px'}}
						>
							where you like to play
						</Link>.
						</p>
					</div>
					<div className='absolute bottom-0 left-1/2 transform -translate-x-1/2 pb-4'>
						<FaChevronDown style={{color: '#FFFF', fontSize: '40px',}}/>
					</div>
				</div>
			</div>


		</section>
	)
}
