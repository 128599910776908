import React from 'react'

export default function About() {
	return (
		<section id='aboutSection' className='bg-white flex mt-30 xl:flex-row gap-4 flex-col sm:py-16 py-6 mt-30'>
			<div className='flex-1 flex justify-center items-start flex-col xl:px-16 sm:px-16 px-6 '>
				<div className='flex flex-row justify-between items-center w-full'>
					<p className='font-cocobold text-left text-4xl sm:text-4xl lg:text-6xl font-bold mb-4'> ABOUT US</p>
				</div>
				{/* Move the kiosk image to sit between headings on small screens */}
				<div className='flex flex-1 lg:hidden w-full justify-left mt-8 mb-8'>
					<img alt={"GoRaqt Kiosk"} src='../Images/GoRaqt-Kiosk.png'
					     className='lg:h-[600px] xl:h-[800px]'/>
				</div>
				<div className='flex flex-row justify-between items-center w-full'>
					<p className='font-cocomedium text-black text-[20pt] leading-[1.2] mt-5 md:text-[30pt]'> GoRaqt is
						revolutionizing racquet sports with solar powered, courtside kiosks stocked for rentals, demos,
						and purchase.</p>
				</div>
				<div className='flex flex-row justify-between items-center w-full mb-[-20px]'>
					<p className='font-cocoregular text-black text-[18pt] mt-5'> Featuring:</p>
				</div>
				<ul className='font-cocoregular text-black text-[18pt] mt-5 leading-[1.5] list-disc pl-6 '>
					<li>Premium racquets customizations</li>
					<li>Pickleball and Paddle paddles</li>
					<li>Germicidal UVC disinfecting sterilization</li>
				</ul>
				<div className="w-full h-[1px] bg-[#000] mt-6"></div>
				<div className='flex flex-row justify-between items-center w-full'>
					<p className='font-cocomedium text-black text-[20pt] mt-5'> Investor inquiries, reach us at <a className='text-red-500' href="mailto:invest@GoRaqt.com">invest@GoRaqt.com</a> <br /> For a GoRaqt Kiosk at your court, resort or pro shop reach us at <a className='text-red-500' href="mailto:kiosk@GoRaqt.com"> kiosk@GoRaqt.com</a></p>
				</div>
				<div className='flex flex-row justify-between items-center w-full'>
					<p className='font-cocothin text-black text-[15pt] mt-20 mb-5'> A freshly minted venture from the
						NYU Tandon School of Engineering Future Labs and Cornell accelerators for technology and
						innovation.</p>
				</div>
				<div className='shrink-0 flex flex-row justify-left items-center w-full'>
					<img src="../Images/nyu-logo-b.png" alt="Logo"
					     className='h-[25px] pr-2'/>
					<img src="../Images/cornell-logo-b.png" alt="Logo"
					     className='h-[25px] pr-2'/>
				</div>
			</div>
			<div className='items-center'>
				<img alt={"GoRaqt Kiosk"} src='../Images/GoRaqt-Kiosk.png'
				     className='hidden xl:block h-[500px] md:h-[600px] lg:h-[600px] xl:h-[800px]'/>
			</div>
		</section>
	)
}
