// Cart_item.js
import React from "react";
import Moment from 'moment';
import "./Cart_item.css";

const Cart_item = ({ item, onRemove }) => {

  return (
    <div className="shopping-cart-item flex shadow-lg shadow-gray-300 rounded-lg justify-between items-center mb-4 mt-4 ml-4 ">
      <div>
        <h3 className="font-semibold">{item.racquetName}</h3>
        {/* <h3 className="font-semibold text-red-600">{item.id}</h3> */}
        <p className="font-cocoregular item-price">{item.duration} hour x $4.99 = ${item.duration*4.99}</p>
          <p className="font-cocoregular" style={{fontSize:.75 + 'em'}}>{Moment(item.date).format('l')}, {item.timeSlot}<span className={"hide-comma"}>,</span> <span className={"add-br"}><br/></span> {item.location}</p>
      </div>
      <div>
        <button onClick={() => onRemove(item.id)} className="text-red-600 mr-2" style={{paddingLeft: 1 + 'em'}}>
          Remove
        </button>
      </div>
    </div>
  );
};

export default Cart_item;
