import React, {useState, useEffect} from "react";
import {RxHamburgerMenu} from "react-icons/rx";
import {TiShoppingCart} from "react-icons/ti";
import {IoClose} from "react-icons/io5";
import {Link} from "react-scroll";
import "./Navbar.css";

import ShoppingCart from "./ShoppingCart"; // Import the ShoppingCart component

const Navlinks = ({closeNavbar}) => {
	return (
		<>
			<Link
				to="racquetsSection"
				smooth={true}
				duration={500}
				offset={-50}
				className="link inline text-[#DFFF4F] text-[18pt] no-underline cursor-pointer pr-16 racquets-nav-position"
				style={{marginTop: 2 + 'px'}}
				onClick={closeNavbar} // Close navbar when clicked
			>
				RACQUETS
			</Link>
			<Link
				to="aboutSection"
				smooth={true}
				duration={500}
				className="link inline text-[#DFFF4F] text-[18pt] no-underline cursor-pointer pr-16 about-nav-position"
				style={{marginTop: 2 + 'px'}}
				onClick={closeNavbar} // Close navbar when clicked
			>
				ABOUT
			</Link>
		</>
	);
};

export default function Navbar({cartItems, removeFromCart}) {
	const [isOpen, setIsOpen] = useState(false);
	const [showLogo, setShowLogo] = useState(true);
	const [showCartOverlay, setShowCartOverlay] = useState(false); // State to manage shopping cart overlay

	useEffect(() => {
		const handleScroll = () => {
			// Check if the user has scrolled past the landing page
			const landingPageHeight = document.getElementById("landing").offsetHeight;
			setShowLogo(window.scrollY > landingPageHeight);
		};

		// Attach scroll event listener
		window.addEventListener("scroll", handleScroll);

		// Cleanup function to remove the event listener
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const toggleNavbar = () => {
		setIsOpen(!isOpen);
	};

	const closeNavbar = () => {
		setIsOpen(false);
	};

	const toggleCartOverlay = () => {
		setShowCartOverlay(!showCartOverlay);
	};

	return (
		<header
			className="font-cocobold fixed top-0 w-full flex-wrap z-50 bg-black shadow-md flex justify-between items-center p-4">
			<div className="hidden md:flex items-center">
				<Link to="landing" smooth={true} duration={500}>
					<img
						src="./Images/logo_with_smile.png"
						alt="GoRaqt Logo"
						className="h-10 mr-4 cursor-pointer"
					/>
				</Link>
			</div>
			<div className="flex justify-end">
				<div className="hidden w-full justify-between md:flex nav-links">
					<Navlinks closeNavbar={closeNavbar}/>
				</div>
				<div className="flex items-center justify-end md:hidden ">
					<button onClick={toggleNavbar} className="">
						{isOpen ? (
							<IoClose style={{color: "#DFFF4F", fontSize: "30px"}}/>
						) : (
							<RxHamburgerMenu style={{color: "#FFFF", fontSize: "30px"}}/>
						)}
					</button>
					{!isOpen && (
						<button className="md:hidden sm:flex items-center">
							<Link to="landing" smooth={true} duration={500}>
								<img
									src="./Images/logo_with_smile.png"
									alt="GoRaqt Logo"
									className="h-10 pl-2 pt-1 mr-4 cursor-pointer"
								/>
							</Link>
						</button>
					)}
				</div>
			</div>
			{isOpen && (
				<div className="flex flex-col items-center basis-full pl-12">
					<Link
						to="landing"
						smooth={true}
						duration={500}
						className="link inline text-[#DFFF4F] text-[18pt] no-underline cursor-pointer pr-16"
						onClick={closeNavbar} // Close navbar when clicked
					>
						HOME
					</Link>
					<Navlinks closeNavbar={closeNavbar}/>
					<div className="link inline text-[#DFFF4F] text-[18pt] no-underline cursor-pointer pr-16"
					     onClick={toggleCartOverlay}>
						CART
					</div>
				</div>
			)}
			{!isOpen && (
				<div className="flex items-center justify-end">
					<TiShoppingCart
						style={{color: "#DFFF4F", fontSize: "40px"}}
						className="cursor-pointer pr-2"
						onClick={toggleCartOverlay} // Toggle cart overlay when clicked
					/>
					{cartItems.length > 0 ? (
						<span className={"badge"}>{cartItems.length}</span>
					) : (
						<span></span>
					)}
				</div>
			)}
			{/* Shopping cart overlay */}
			{showCartOverlay && (
				<ShoppingCart
					cartItems={cartItems} // Pass your cart items here
					removeFromCart={removeFromCart}
					onClose={toggleCartOverlay} // Close cart overlay when clicked
				/>
			)}
		</header>
	);
}
