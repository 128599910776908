import React, { useState } from "react";
import "./App.css";
import Navbar from "./Navbar";
import Landing from "./Landing";
import About from "./About";
import Racquets from "./Racquets";
import { v4 as uuidv4 } from 'uuid'; // Import the uuid library

function App() {
  const [cartItems, setCartItems] = useState([]);

  const addToCart = (item) => {
    // Generate a unique identifier for the item
    const newItem = { ...item, id: uuidv4() };
      setCartItems([...cartItems, newItem]);
  };
  // Function to remove an item from the cart
  const removeFromCart = (itemId) => {
    setCartItems(cartItems.filter((item) => item.id !== itemId));
  };

  return (
    <div>
      <Navbar cartItems={cartItems} removeFromCart={removeFromCart}></Navbar>

      <Landing></Landing>

      <Racquets addToCart={addToCart}></Racquets>
      <About></About>
    </div>
  );
}

export default App;
