// BookingDialogueBox.js
import React, {useState, useEffect} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./BookingDialogueBox.css";

const BookingDialogueBox = ({isOpen, onClose, racquetId, racquetName, addToCart}) => {
	const [date, setDate] = useState(new Date());
// State for form inputs
	const [formData, setFormData] = useState({
		racquetName: racquetName,
		emailAddress: "",
		date: "",
		duration: "1", // Default duration value
		location: "", // Default location value
	});
	const [touched, setTouched] = useState(false);
	const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
	// Handler for form input changes
	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormData({...formData, [name]: value});
		setTouched(true);
	};

	function convertDateFormat(dateString) {
		// Split the date string by '/'
		const parts = (new Date(dateString).toLocaleDateString('en-US', {
			day: "2-digit",
			month: "2-digit",
			year: 'numeric'
		}).replaceAll("/", "-")).split("-")

		console.log(`${parts[2]}-${parts[0]}-${parts[1]}`);
		return `${parts[2]}-${parts[0]}-${parts[1]}`;
	}

	const fetchAvailableTimeSlots = async () => {
		try {
			const queryParams = new URLSearchParams({
				racquet_id: racquetId, // Replace with formData.racquetName or appropriate value
				date: convertDateFormat(formData.date),
				mins: formData.duration * 60,
			});
			const url = `https://10md8xfvaj.execute-api.us-east-1.amazonaws.com/prod/api/get_available_timeslots?${queryParams}`;

			const response = await fetch(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});

			const data = await response.json();
			setAvailableTimeSlots(data.available_timeslots);
		} catch (error) {
			console.error("Error fetching available time slots:", error);
		}
	};
	// Effect hook to fetch available time slots when racquet name, date, or duration changes
	useEffect(() => {
		// Check if racquet name, date, and duration are filled properly
		if (isValidDate(formData.date) && formData.duration) {
			// Fetch available time slots
			fetchAvailableTimeSlots();
		}
	}, [formData.date, formData.duration]);
	// Function to handle input change for time slot
	const handleTimeSlotChange = (event) => {
		const {name, value} = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};
	const isValidDate = (dateString) => {
		return (new Date(dateString)).setHours(0, 0, 0, 0) >= (new Date()).setHours(0, 0, 0, 0);
	};

	const modTimeslots = (timeSlot) => {
		return timeSlot.replace(/^0/g, ' ')
			.replace(/- 0/g, '-  ')
			.replace(/13/g, " 1")
			.replace(/14/g, " 2")
			.replace(/15/g, " 3")
			.replace(/16/g, " 4")
			.replace(/17/g, " 5")
			.replace(/18/g, " 6")
			.replace(/19/g, " 7")
			.replace(/20/g, " 8")
			.replace(/:00:00/g, ":00")
	};


	// Handler for form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		// Process form data (e.g., submit to backend)
		const formDataWithId = {
			...formData,
			racquetId: racquetId
		};
		formDataWithId.date = convertDateFormat(formDataWithId.date, 'yyyy-MM-dd')
		addToCart(formDataWithId); // Call addToCart function
		onClose();
	};
	const slotLength = {
	}
	const dialogStyle = {
		width: "calc(100vw - 40px)", // Adjust the width as needed
		maxWidth: "600px", // Set a maximum width if necessary
		padding: "30px", // Adjust padding as needed
	};
	return (
		<div
			className={`fixed inset-0 z-50 flex justify-center items-center ${
				isOpen ? "" : "hidden"
			}`}
		>
			<div
				className="fixed inset-0 bg-black bg-opacity-50"
				onClick={onClose}
			></div>
			<div className="bg-white rounded-lg absolute" style={dialogStyle}>
				<h1 className="text-xl text-left font-bold mb-4">Reserve</h1>
				<button className="absolute top-2 right-2" onClick={onClose}>
					<svg
						className="w-6 h-6 text-gray-600"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						></path>
					</svg>
				</button>
				{/* Form */}
				<form onSubmit={handleSubmit}>
					<div className="mb-4 text-left">
						<label
							htmlFor="racquetName"
							className="block text-sm font-medium text-gray-700"
						>
							Racquet Name
						</label>
						<input
							type="text"
							id="racquetName"
							name="racquetName"
							value={racquetName}
							onChange={handleInputChange}
							className="mt-1 p-2 block w-full rounded-md border border-black shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
							placeholder="Enter racquet name"
							readOnly
						/>
					</div>

					<div className="mb-4 text-left">
						<label
							htmlFor="date"
							className="block text-sm font-medium text-gray-700"
						>
							Date
						</label>
						<DatePicker
							type="string"
							id="date"
							name="date"
							format="MM-dd-yyyy"
							selected={formData.date}
							value={formData.date}
							onChange={date => handleInputChange({target: {value: date, name: 'date'}})}
							className="w-full mt-1 p-2 rounded-md border border-black shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
							wrapperClassName="date-picker"
							placeholderText="Enter date (mm-dd-yyyy)"
							required
						/>
						{touched && !isValidDate(formData.date) && (
							<div className="ml-0 text-red-400">Please enter a valid date</div>
						)}
					</div>
					<div className="mb-4 text-left">
						<label
							htmlFor="duration"
							className="block text-sm font-medium text-gray-700"
						>
							Duration
						</label>
						<select
							id="duration"
							name="duration"
							value={formData.duration}
							onChange={handleInputChange}
							className="mt-1 p-2 block w-full rounded-md border border-black shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
							placeholder="Select Duration"
							required
						>
							<option value="1">1 hour</option>
							<option value="2">2 hours</option>
						</select>
					</div>
					<div className="mb-4 text-left">
						<label
							htmlFor="timeSlot"
							className="block text-sm font-medium text-gray-700"
						>
							Time Slot
						</label>

						<select
							id="timeSlot"
							name="timeSlot"
							value={formData.timeSlot}
							onChange={handleTimeSlotChange}
							className="mt-1 p-2 block w-full rounded-md border border-black shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
							required
						>
							<option value="">Select a time slot</option>
							{availableTimeSlots.map((slot, index) => (
								<option key={index} value={slot}>
									{modTimeslots(slot)}
								</option>
							))}
						</select>
					</div>
					<div className="mb-4 text-left">
						<label
							htmlFor="location"
							className="block text-sm font-medium text-gray-700"
						>
							Location
						</label>
						<select
							type="text"
							id="location"
							name="location"
							value={formData.location}
							onChange={handleInputChange}
							className="mt-1 p-2 block w-full rounded-md border border-black shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
							required
						>
							<option value="0">Select Location</option>
							<option value="Fort Greene">Fort Greene</option>
							<option value="Van Vorhees, Cobble Hill">Van Vorhees, Cobble Hill</option>
							<option value="South Oxford Park">South Oxford Park</option>
						</select>
					</div>
					<div className="flex justify-between">
						<button
							type="submit"
							onClick={handleSubmit}
							className="bg-[#DFFC4F] hover:bg-[#288ECE] text-black font-bold py-2 px-4 rounded"
						>
							Add to Cart
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default BookingDialogueBox;
