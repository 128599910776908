import {loadStripe} from "@stripe/stripe-js";
import React, {useState, useEffect, useCallback} from "react";
import Cart_item from "./Cart_item";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ShoppingCart.css";

import {
	useStripe,
	EmbeddedCheckoutProvider,
	EmbeddedCheckout,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
	"pk_live_51OttHkJ9QCGJeWTySWkujgTTpIo5pM4XsaFifGVle5KHIaxxL02nN6YesP8Do0JH7hiZqIIdGq47bpnedAk8PN1T00lHbyOM2X"
);

const ShoppingCart = ({onClose, cartItems, removeFromCart}) => {
	const [sessionID, setSessionID] = useState(null);
	let fetchClientSecret = useCallback(async () => {
		// Create a Checkout Session
		return fetch("https://10md8xfvaj.execute-api.us-east-1.amazonaws.com/prod/api/create-checkout-session", {
			method: "POST",
			body: JSON.stringify({cartItems}), // Send cart items to the backend
			headers: {
				"Content-Type": "application/json",
			},
		})
			.catch(err => {
				console.log(err.message)
			})
			.then((res) => res.json())
			.then((data) => {
				setSessionID(data.clientSecret);
				return data.clientSecret;
			});
	}, [cartItems]);
	const [checkoutStarted, setCheckoutStarted] = useState(false);
	const [checkoutSuccess, setCheckoutSuccess] = useState(false);
	const options = {fetchClientSecret};
	const makePayment = async () => {
		setCheckoutStarted(true);
	};
	const handleComplete = async () => {
		setCheckoutSuccess(true);
	};
	useEffect(() => {
		if (checkoutSuccess && sessionID) {
			handleCheckoutComplete(sessionID, cartItems);
		}
	}, [checkoutSuccess, sessionID]);

	const handleCheckoutFailure = () => {
		console.log("Checkout failed")
	}
	const getSessionStatus = async (sessionID) => {
		try {
			const trimmedSessionID = sessionID.slice(0, 66); // Keep only the first 66 characters
			const response = await fetch(`https://10md8xfvaj.execute-api.us-east-1.amazonaws.com/prod/api/session-status?session_id=${trimmedSessionID}`)
				.catch(err => {
					console.log(err.message)
				});
			const data = await response.json();
			return data;
		} catch (error) {
			return null;
		}
	};


	const handleCheckoutComplete = useCallback(async (sessionIDPassed, cartItems) => {
		try {
			const sessionStatus = await getSessionStatus(sessionIDPassed);
			if (sessionStatus["status"] === "complete") {
				try {
					const response = await fetch("https://10md8xfvaj.execute-api.us-east-1.amazonaws.com/prod/api/checkout-complete", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							cartItems: cartItems,
							email: sessionStatus["customer_email"]
						}),
					})
						.catch(err => {
							console.log(err.message)
						});

					const data = await response.json();
					if (data.success) {
						cartItems.map((item) => (
							removeFromCart(item.id)
						))
					} else {
						toast.error('Payment unsuccessful, please contact support@goraqt.com');
					}
				} catch (error) {
					console.error("Error completing checkout:", error);
				}
			} else {
				toast.error('Payment unsuccessful, please contact support@goraqt.com');
			}
		} catch (error) {
			toast.error('Payment unsuccessful, please contact support@goraqt.com');
		}
	}, [cartItems]);

	return (
		<div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
			<div
				className={"bg-white shadow-2xl shadow-gray-900 rounded-lg max-w-[90%] max-h-[90%] shopping-cart-modal-box overflow-auto " + (!checkoutStarted ? 'p-24' : '')}>
				<div
					className={"flex justify-between mb-4 shopping-cart-content " + (checkoutStarted ? 'pt-24 pl-20 -mb-16' : '')}>
					<h2 className="text-2xl font-bold">Shopping Cart</h2>
					<button
						onClick={onClose}
						className={"text-gray-600 hover:text-gray-800 " + (checkoutStarted ? 'mr-4' : '')}
					>
						X
					</button>
				</div>
				{!checkoutStarted && (
					<div style={{marginTop: 1.5 + 'em', marginLeft: -2 + 'em'}}>
						{cartItems.length === 0 ? (
							<p>Your cart is empty.</p>
						) : (
							<div className={"shopping-cart-inner-box"}>
								{cartItems.map((item) => (
									<Cart_item
										key={item.id}
										item={item}
										onRemove={removeFromCart}
									/>
								))}
								<button
									onClick={makePayment}
									className="font-cocobold bg-[#DFFC4F] text-black py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 block text-left"
									style={{marginLeft: .75 + 'em'}}
								>
									Go to checkout
								</button>
							</div>
						)}
					</div>
				)}
				{checkoutStarted && (
					<div id="checkout" className="flex justify-center pt-24">
						<EmbeddedCheckoutProvider
							stripe={stripePromise}
							options={{
								...options,
								onComplete: handleComplete,
							}}
						>
							<EmbeddedCheckout/>
						</EmbeddedCheckoutProvider>
					</div>
				)}
			</div>
			<ToastContainer/>
		</div>
	);

};

export default ShoppingCart;
