import React, { useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    email: "",
    zipcode: "",
    sport: "",
    friendEmail: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Construct the query string from the form data
      const queryParams = new URLSearchParams({
        user_zip: formData.zipcode, // Replace with formData.racquetName or appropriate value
        user_email: formData.email,
        sport: formData.sport,
        f_email: formData.friendEmail
      });

      // Append the query parameters to the URL
      const url = `https://10md8xfvaj.execute-api.us-east-1.amazonaws.com/prod/api/add_contact?${queryParams}`;

      // Send a GET request with the constructed URL
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }
      toast.success('Thank you, someone will contact you soon!');
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };



  return (
      <div className="form-container pt-2 pb-12 mt-2 md:mt-0">
        <div className="max-w-lg mx-auto p-2 bg-white ">
          <h2 className="font-cocobold text-left text-4xl sm:text-4xl lg:text-6xl font-bold mb-4">Where do you like to play? Let us know and your first demo racquet is free.</h2>

          <p className="font-cocomedium text-left text-2xl mb-6">Enter your email and zip code for updates</p>
          <form onSubmit={handleSubmit} className=" space-y-6">
            <div>
              <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="font-cocoregular mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="Enter your email address"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <input
                    type="text"
                    id="zipcode"
                    name="zipcode"
                    value={formData.zipcode}
                    onChange={handleInputChange}
                    required
                    pattern="\d{5}"
                    className="font-cocoregular mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="Enter your zip code"
                />
              </div>
              <div>
                {/* <label htmlFor="sport" className="block font-medium text-gray-700">
              Select Sport:
            </label> */}
                <select
                    id="sport"
                    name="sport"
                    value={formData.sport}
                    onChange={handleInputChange}
                    required
                    className="font-cocoregular mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="">Select One</option>
                  <option value="Tennis">Tennis</option>
                  <option value="Padel">Padel</option>
                  <option value="Pickleball">Pickleball</option>
                </select>
              </div>
            </div>
            <div>

              <input
                  type="email"
                  id="friendEmail"
                  name="friendEmail"
                  value={formData.friendEmail}
                  onChange={handleInputChange}
                  className="font-cocoregular mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="Share! - Enter your friend's email"
              />
            </div>
            <button
                type="submit"
                className="font-cocobold bg-[#DFFC4F] text-black py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 block text-left"
            >
              Submit
            </button>
          </form>
        </div>
        <ToastContainer/> {/* Include ToastContainer to render toast notifications */}
      </div>
  );
};

export default ContactUs;
